const routes = {
  portal: {
    index: '/',
    login: '/login',
    home: '/home',
    account: '/account',
    support: '/support',
    coverageCenter: '/coverage',
  },
  registration: {
    index: '/registration',
    contact: '/registration/contact',
    email: '/registration/email',
    insurance: '/registration/insurance',
    credentials: '/registration/credentials',
    survey: '/registration/survey',
    review: '/registration/review',
    success: '/registration/success',
  },
};

export default routes;
